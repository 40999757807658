import React, { useEffect, useState } from 'react';
import { Container, Paper, Box, Typography, Link, Button } from '@mui/material';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

import { useNavigate, useLocation } from 'react-router-dom';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { set } from 'lodash';

function ResumeSuccessPurchase() {
	const navigate = useNavigate();
	const query = new URLSearchParams(useLocation().search);

	const sessionid = query.get('session_id');
	const item = query.get('item');

	const [isVerified, setIsVerified] = useState(false);
	const [paymentMessage, setPaymentMessage] = useState(null);
	const [verificationInProgress, setVerificationInProgress] = useState(true);
	const [email, setEmail] = useState('');
	const [userid, setUserid] = useState('');

	useEffect(() => {
		async function verifySession() {
			try {
				const user = await Auth.currentAuthenticatedUser();
				const email = user.attributes.email;
				setEmail(user.attributes.email);
				const userid = user.username;
				setUserid(user.username);

				// Remove the manual token handling
				const requestInfo = {
					body: {
						sessionid,
						userid,
						email,
					},
				};

				// Let Amplify handle the authorization
				const response = await API.post(
					'checkoutStripe',
					'/validatestripe',
					requestInfo
				);

				const { paymentSuccessful, paymentMessage } = response;

				if (paymentSuccessful) {
					setIsVerified(true);
				}
			} catch (error) {
				console.error('Error verifying session', error);
			} finally {
				setVerificationInProgress(false);
			}
		}

		if (sessionid) {
			//console.log("We have a sessionid: ", sessionid)
			verifySession();
		}
	}, []);

	useEffect(() => {
		//console.log("LOGGING: Entering useEffect() with isVerified: ", isVerified)
		if (isVerified) {
			const timer = setTimeout(() => {
				navigate('/resumereports');
			}, 7000);

			return () => clearTimeout(timer);
		}
	}, [isVerified, navigate]);

	if (verificationInProgress) {
		return <Typography>Verifying your purchase...</Typography>;
	}

	const handleRedirect = () => {
		navigate('/resumereports');
	};

	if (!isVerified) {
		const error = `Unable to verify purchase. It's possible Stripe has charged your credit card. Do not re-run your credit card. You will be charged again.${
			paymentMessage ? ` The error we have is: ${paymentMessage}` : ''
		}`;

		return (
			<Container maxWidth="med">
				<Paper elevation={3} sx={{ mt: 8, p: 4, textAlign: 'center' }}>
					<SentimentDissatisfiedIcon
						sx={{ fontSize: 100, color: 'action.active' }}
					/>
					<Typography variant="h6">{error}</Typography>
					<Typography variant="subtitle1">
						I give you permission to call or txt me right now so we
						can get this sorted. +1 303-868-8836
					</Typography>
					<Typography variant="subtitle1">
						email also works:
						<Link href="mailto:bwatson@kompartners.com">
							bwatson@kompartners.com
						</Link>
					</Typography>
					<Typography variant="h6">
						Here is some information that might help me debug:
					</Typography>
					<Typography variant="body1">
						Purcahse account email: {email}
					</Typography>
					<Typography variant="body1">
						Your accountId: {userid}
					</Typography>
					<Typography variant="body1">
						Claimed purchased item: {item}
					</Typography>
					<Typography variant="body1">
						Claimed sessionId: {sessionid}
					</Typography>
				</Paper>
			</Container>
		);
	}

	return (
		<Container maxWidth="sm">
			<Paper elevation={3} sx={{ mt: 8, p: 4, textAlign: 'center' }}>
				<SentimentSatisfiedIcon
					sx={{ fontSize: 100, color: 'action.active' }}
				/>
				<Typography variant="h4" gutterBottom>
					You have successfully purchased a Resume Report{' '}
					{item ? `${item}` : ''} from HiredUpon!
				</Typography>
				<Typography variant="subtitle1" gutterBottom>
					Thank you so much for your trust and willingness to use our
					services. You can now use the service to place orders or
					review past orders.
				</Typography>
				<Typography variant="body1" sx={{ my: 2 }}>
					You will be auto-redirected back to the Resume Reports page
					in a few moments. If you wish to proceed immediately, click
					the button below.
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={handleRedirect}
				>
					Return to Resume Reports
				</Button>
			</Paper>
		</Container>
	);
}

export default ResumeSuccessPurchase;
