import React from 'react';
import { Container, Grid } from '@mui/material';

import ResumeReportCta from './ResumeReportCta';

function ResumeLicensePurchase({ user }) {
	const productIdList = process.env.REACT_APP_RESUME_LICENSE_IDS.split(',');

	return (
		<Container maxWidth="lg">
			{/* <Box my={4} textAlign="center">
                <Typography variant="h4" gutterBottom>
                    Purchase  One-Time License
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                In order to use HiredUpon, you must purchase a usage credits. In this case you are buying credits to run Resume Reports.
                </Typography>
            </Box> */}

			<Grid container spacing={4} justifyContent="center">
				{/* <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ResumeLicenseProductCard
                        productDetails={productDetails}
                        purchaseClick={redirectToCheckout}
                        ctaText={ctaText}
                        />
                </Grid> */}
				{productIdList.map((productId) => {
					return (
						<ResumeReportCta
							key={productId}
							productId={productId}
						/>
					);
				})}
			</Grid>

			{/* <FeatureHighlights /> */}
			{/* <Testimonials /> */}
			{/* <LicenseFaq /> */}
		</Container>
	);
}

export default ResumeLicensePurchase;
