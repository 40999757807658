import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Grid } from '@mui/material';

import { Auth, API } from 'aws-amplify';

import ReactGA from 'react-ga4';

// import awsmobile from '../aws-exports'

import { loadStripe } from '@stripe/stripe-js';
import Stripe from 'stripe';
//not sure i need this one yet

import ResumeLicenseProductCard from './ResumeLicenseProductCard';

function ResumeReportCta({ productId }) {
	const navigate = useNavigate();

	const [productDetails, setProductDetails] = useState(null);
	const [showSignupCta, setShowSignupCta] = useState(true);
	const [numCredits, setNumCredits] = useState(0);

	const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

	let ctaTitles = {};
	const stage = process.env.REACT_APP_USER_BRANCH;
	// console.log("productId: ", productId)

	if (stage === 'dev') {
		ctaTitles = {
			prod_PD9xai7leMdDve: 'Low Risk Way to Start - Single Report',
			prod_P8frVlXeZUo39Z: 'Best Value - Report Bundle',
		};
	} else if (stage === 'prod') {
		ctaTitles = {
			prod_PDAae2Y6DYRulQ: 'Low Risk Way to Start - Single Report',
			prod_P8frnyMSlTNi0Y: 'Best Value - Report Bundle',
		};
	}

	async function fetchProductDetails(productId) {
		try {
			// THIS IS THE OLD CODE WHICH USED PRIVATE KEY
			// const product = await privatestripe.products.retrieve(productId)
			// // console.log("product: ", product)
			// setNumCredits(parseInt(product.metadata.credits,10))

			// const prices = await privatestripe.prices.list({ product: productId })
			// // console.log("prices: ", prices)
			// setProductDetails({ product, prices }) // Assuming there's at least one price

			// THIS IS THE NEW CODE WHICH USES PUBLIC KEY
			console.log(`fetchProductDetails(): productId: ${productId}`);

			const requestInfo = {
				body: { productId },
			};

			const response = await API.post(
				'checkoutStripe',
				'/product',
				requestInfo
			);

			const { product, prices } = response;
			setNumCredits(parseInt(product.metadata.credits, 10));
			setProductDetails({ product, prices });
		} catch (error) {
			console.error('Error fetching product details:', error);
		}
	}

	async function redirectToCheckout(priceid) {
		ReactGA.event({
			action: 'click',
			category: 'button_click',
			label: `ResumeReportCta: redirected to checkout for pricedId: ${priceid}`,
			value: 0,
			nonInteraction: false,
		});

		try {
			// Verify user is authenticated
			const user = await Auth.currentAuthenticatedUser();
			if (!user) {
				throw new Error('User must be authenticated to checkout');
			}

			const userid = user.username;
			const useremail = user.attributes.email;

			const credits = parseInt(
				productDetails.product.metadata.credits,
				10
			);

			const requestInfo = {
				body: {
					priceid,
					userid,
					useremail,
					credits,
				},
			};

			console.log('Sending checkout request with:', {
				...requestInfo,
				headers: { Authorization: 'REDACTED' },
			});

			// Let Amplify handle the authorization headers automatically
			const response = await API.post(
				'checkoutStripe',
				'/checkout',
				requestInfo
			);

			console.log('Checkout response:', response);

			const { sessionId } = response;

			const publicstripe = await loadStripe(
				process.env.REACT_APP_STRIPE_PUBLIC_KEY
			);
			await publicstripe.redirectToCheckout({ sessionId });
		} catch (error) {
			if (error.response) {
				console.error('Server Response Error:', {
					status: error.response.status,
					data: error.response.data,
				});
			}
			console.error('ERROR: redirectToCheckout():', error);
			throw error;
		}
	}

	async function redirectToSignup() {
		ReactGA.event({
			action: 'click',
			category: 'button_click',
			label: 'ResumeReportCta: redirected to signup',
			value: 0,
			nonInteraction: false,
		});

		navigate('/signin');
	}

	useEffect(() => {
		// const productId = process.env.REACT_APP_RESUME_LICENSE_PRODID
		// const productIdList = process.env.REACT_APP_RESUME_LICENSE_IDS

		async function checkAuth() {
			try {
				const user = await Auth.currentAuthenticatedUser();
				if (user) {
					setShowSignupCta(false);
				}
			} catch (error) {
				//console.info("info: ", error)
			}
		}

		checkAuth();
		fetchProductDetails(productId);
	}, []);

	if (
		!productDetails ||
		!productDetails.prices ||
		!productDetails.prices.data ||
		productDetails.prices.data.length === 0
	) {
		return <Typography>Loading product details...</Typography>;
	}

	return (
		<Container maxWidth="lg">
			<Box my={4} textAlign="center">
				{ctaTitles[productId] && (
					<Typography variant="h4" gutterBottom>
						{ctaTitles[productId]}
					</Typography>
				)}
			</Box>

			<Grid container spacing={4} justifyContent="center">
				<Grid
					item
					xs={12}
					md={12}
					lg={12}
					sx={{ display: 'flex', justifyContent: 'center' }}
				>
					<ResumeLicenseProductCard
						productDetails={productDetails}
						purchaseClick={
							showSignupCta
								? redirectToSignup
								: redirectToCheckout
						}
						ctaText={
							showSignupCta
								? 'Sign Up'
								: `Purchase ${numCredits} Credits`
						}
					/>
				</Grid>
			</Grid>
		</Container>
	);
}

export default ResumeReportCta;
